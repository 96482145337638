<template>
  <div class="row">
    <div class="col">
      <datatable
        ref="datatable"
        :url="datatable.url"
        :fields="datatable.fields"
        :filters="datatable.filters"
        :sort-order="datatable.sortOrder"
        :on-pagination-data="onPaginationData"

        :transform="transform"
      >
        <!-- begin::Critical -->
        <template slot="actions" slot-scope="props">
          <button
            type="button"
            class="btn btn-sm btn-info w-150px"
            @click.prevent="onShowDetailsButtonClick(props.rowData)"
          >
            <span class="svg-icon align-middle">
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </span>
            <span>Show details</span>
          </button>

          <b-dropdown
            ref="actions-dropdown"
            variant="clean btn-sm btn-icon"
            class="dropdown-inline mr-2"
            right
            lazy
            no-caret
            boundary="window"
            menu-class="dropdown-menu-md"
          >
            <template v-slot:button-content>
              <span class="svg-icon">
                <font-awesome-icon :icon="['fas', 'cog']" />
              </span>
            </template>

            <template v-slot:default>
              <ul class="navi flex-column navi-hover py-2">
                <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                  Choose an action:
                </li>

                <li class="navi-separator my-0" />

                <template v-if="props.rowData.json_data && props.rowData.json_data.paraId">
                  <li class="navi-item">
                    <a class="navi-link cursor-pointer" @click.prevent="showCorrespondingSettings(props.rowData)">
                      <span class="navi-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'cogs']" class="w-15px h-15px" />
                        </i>
                      </span>
                      <span class="navi-text">Corresponding settings</span>
                    </a>
                  </li>

                  <li class="navi-separator my-0" />
                </template>

                <template>
                  <li class="navi-item">
                    <a class="navi-link cursor-pointer" @click.prevent="downloadLogFile(props.rowData, { query: { format: 'csv' } })">
                      <span class="navi-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'file-csv']" class="w-15px h-15px" />
                        </i>
                      </span>
                      <span class="navi-text">Download CSV</span>
                    </a>
                  </li>

                  <li class="navi-item">
                    <a class="navi-link cursor-pointer" @click.prevent="downloadLogFile(props.rowData, { query: { format: 'json' } })">
                      <span class="navi-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'file-code']" class="w-15px h-15px" />
                        </i>
                      </span>
                      <span class="navi-text">Download JSON</span>
                    </a>
                  </li>
                </template>
              </ul>
            </template>
          </b-dropdown>
        </template>
        <!-- end::Critical -->
      </datatable>

      <!-- begin::Datatable footer -->
      <div class="datatable datatable-default">
        <div class="datatable-pager datatable-paging-loaded">
          <datatable-pagination
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />

          <datatable-pagination-info
            ref="pagination.info"
            @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
          />
        </div>
      </div>
      <!-- end::Datatable footer -->
    </div>

    <!-- begin::Modals -->
    <session-details-modal ref="sessionDetailsModal" :equipment="equipment" :device="device" />
    <!-- end::Modals -->
  </div>
</template>

<script>
import { DatatableMixin } from '@vedicium/metronic-vue';
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';
import moment from 'moment-timezone';
import format from '@/libs/format';

import Equipment from '@/libs/classes/equipment';
import LuxuraLogSession from '@/libs/classes/luxura_log.session';

import sessionDetailsModal from './modals/session-details/index.vue';

export default {
  mixins: [DatatableMixin, equipmentMixin],
  data () {
    return {
      datatable: {
        url: `${Equipment.uri}/${this.equipment._meta.guid}/logs`,
        fields: [{
          name: 'log_date',
          title: 'Date',
          sortField: 'log_date',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
          formatter: (value) => moment.utc(value).tz(this.equipment.location.timezone || 'UTC').format('LLL'),
        }, {
          name: 'session.session_time',
          title: 'Session time',
          sortField: 'session.session_time',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
          formatter: (value) => format.humanizeSessionTime(value),
        }, {
          name: 'log_id',
          title: 'Log',
          sortField: 'log_id',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
          formatter: (value) => `#${value}`,
        }, {
          name: 'controller_serial',
          title: 'Controller',
          sortField: 'controller_serial',
          titleClass: 'w-150px',
          dataClass: 'w-150px',
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-125px',
          dataClass: 'w-125px',
        }],

        sortOrder: [{
          field: 'log_date',
          direction: 'desc',
        }],
        filters: {
          type: 'session',
        },
      },
    };
  },
  components: {
    sessionDetailsModal,
  },

  mounted () {
    // Show result if query is set
    if (typeof this.$route.query.show_result === 'number') {
      const { vuetable } = this.$refs.datatable.$refs;
      vuetable.$once('vuetable:loaded', () => {
        if (vuetable.tableData && vuetable.tableData[this.$route.query.show_result]) {
          this.onShowDetailsButtonClick(vuetable.tableData[this.$route.query.show_result]);
        }
      });
    }
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new LuxuraLogSession().merge(row));
      }
      return response;
    },

    onShowDetailsButtonClick (log = null) {
      if (!log || log instanceof LuxuraLogSession === false) {
        return;
      }

      this.$eventhub.emit('modals:equipment:statistics:session-details:open', {
        title: `Session #${log.log_id}`,
        log,
      });
    },

    async showCorrespondingSettings (log = null) {
      if (!log || log instanceof LuxuraLogSession === false) {
        return;
      }

      this.$refs.datatable.setLoading(true);
      try {
        const documents = await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/logs`, {
          query: {
            filter: {
              type: 'parameter',
              log_id: Number(log.json_data.paraId),
              corresponding_serial: log.corresponding_serial,
            },
          },
        });

        if (documents.length === 0) {
          throw new Error('Corresponding settings not found.');
        }

        this.$router.push({
          name: '/sunbeds/view/:guid/settings/sunbed',
          query: {
            log: documents[0]._meta.guid,
          },
        });
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },
    async downloadLogFile (log = null, options = {}) {
      if (!log || log instanceof LuxuraLogSession === false) {
        return;
      }

      this.$refs.datatable.setLoading(true);
      try {
        await log.download(options);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },
  },
};
</script>
