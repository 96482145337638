<template>
  <b-modal
    ref="modal"

    :title="(options && options.title ? options.title : 'Session details')"

    size="xl"
    centered
  >
    <template v-slot:default>
      <div class="container">
        <!-- begin::Session details -->
        <div class="row row-cols-1 row-cols-lg-3">
          <div class="col mt-3 border-bottom">
            <label class="text-upper">Date & time</label>
            <div>
              <h5 class="overflow-ellipsis">
                {{ $moment.utc(log.log_date).tz(equipment.location.timezone || 'UTC').format('LLL') }}
              </h5>
            </div>
          </div>

          <div class="col mt-3 border-bottom">
            <label class="text-upper">Session time</label>
            <div>
              <h5 class="overflow-ellipsis">
                {{ format.humanizeSessionTime(log.session.session_time) }}
              </h5>
            </div>
          </div>

          <div class="col mt-3 border-bottom">
            <label class="text-upper">Pause time</label>
            <div>
              <h5 class="overflow-ellipsis">
                {{ format.humanizeSessionTime(log.session.pause_time) }}
              </h5>
            </div>
          </div>
        </div>
        <!-- end::Session details -->

        <!-- begin::Temperature information -->
        <template>
          <!-- begin::Legacy device alert -->
          <template v-if="equipment.isDeviceLinked() && device.isLegacyDevice()">
            <div class="row mt-3">
              <div class="col-12">
                <div class="alert alert-custom alert-light-info ml-auto mr-auto w-100 p-3 d-block">
                  <div
                    class="alert-text"
                    v-html="$t('sunbeds.legacy_device_warning')"
                  />
                </div>
              </div>
            </div>
          </template>
          <!-- end::Legacy device alert -->

          <!-- begin::Lamp temperature information -->
          <template v-else-if="!!lampTemperatureSensor">
            <div class="row row-cols-1 row-cols-lg-3">
              <div class="col mt-3 border-bottom">
                <label class="text-upper">Average lamp temperature</label>
                <div>
                  <h5 class="overflow-ellipsis">
                    <template v-if="temperatures.isLoading">?</template>
                    <template v-else>{{ (averageLampTemperature ? `${(averageLampTemperature / 10).toFixed(1)} &deg;C` : 'N/A') }} </template>
                  </h5>
                </div>
              </div>

              <div class="col mt-3 border-bottom">
                <label class="text-upper">Maximum lamp temperature</label>
                <div>
                  <h5 class="overflow-ellipsis">
                    <template v-if="temperatures.isLoading">?</template>
                    <template v-else>{{ (maximumLampTemperature ? `${(maximumLampTemperature / 10).toFixed(1)} &deg;C` : 'N/A') }}</template>
                  </h5>
                </div>
              </div>

              <div class="col mt-3 border-bottom">
                <label class="text-upper">Minimum lamp temperature</label>
                <div>
                  <h5 class="overflow-ellipsis">
                    <template v-if="temperatures.isLoading">?</template>
                    <template v-else>{{ (minimumLampTemperature ? `${(minimumLampTemperature / 10).toFixed(1)} &deg;C` : 'N/A') }}</template>
                  </h5>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <div class="card card-custom">
                  <!-- begin::Loading -->
                  <template v-if="temperatures.isLoading">
                    <div class="loading-container loading-overlap">
                      <div class="loading-backdrop" />
                      <div class="loading-block">
                        <div class="blockui ml-auto mr-auto w-100">
                          <span class="float-left">Gathering data...</span>
                          <span class="spinner spinner-primary ml-auto" />
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- end::loading -->

                  <div class="card-header flex-wrap">
                    <div class="card-title">
                      <h3 class="card-label">
                        Lamp temperature
                        <span class="text-muted pt-2 font-size-sm d-block">Course of the lamp temperature during session.</span>
                      </h3>
                    </div>
                  </div>

                  <apexchart
                    ref="chart"
                    class="card-rounded-bottom h-250 mt-auto"
                    :type="temperatures.chart.type"
                    :height="temperatures.chart.height"
                    :options="lampTemperatureChartOptions"
                    :series="temperatures.chart.series"
                  />
                </div>
              </div>
            </div>
          </template>
          <!-- end::Lamp temperature information -->
        </template>
        <!-- end::Temperature information -->
      </div>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" @click.prevent="close">Close</button>
    </template>
  </b-modal>
</template>

<script>
import ApexCharts from 'vue-apexcharts';
import { Mixins } from '@vedicium/metronic-vue';
import { cloneDeep } from 'lodash';
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';
import format from '@/libs/format';

import Equipment, { SunbedHealth } from '@/libs/classes/equipment';
import LuxuraLogSession from '@/libs/classes/luxura_log.session';

export default {
  mixins: [Mixins.Modal, equipmentMixin],
  name: 'sessionDetailsModal',
  data () {
    return {
      event: 'equipment:statistics:session-details',

      log: null,
      options: null,

      temperatures: {
        isLoading: false,
        dateRange: [],
        chart: {
          type: 'line',
          height: 400,
          options: {
            chart: {
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            plotOptions: {},
            colors: [
              this.$metronic.config.layout.get('colors.theme.base.primary'),
              this.$metronic.config.layout.get('colors.theme.base.info'),
              this.$metronic.config.layout.get('colors.theme.base.info'),
            ],
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              type: 'solid',
              opacity: [1, 1, 1],
              colors: [
                this.$metronic.config.layout.get('colors.theme.light.primary'),
                this.$metronic.config.layout.get('colors.theme.base.info'),
                this.$metronic.config.layout.get('colors.theme.base.info'),
              ],
            },
            stroke: {
              show: true,
              curve: 'smooth',
              lineCap: 'round',
              width: 3,
              colors: [
                this.$metronic.config.layout.get('colors.theme.base.primary'),
                this.$metronic.config.layout.get('colors.theme.base.info'),
                this.$metronic.config.layout.get('colors.theme.base.info'),
              ],
            },
            xaxis: {
              type: 'datetime',
              min: null,
              max: null,
              datetimeUTC: false,
              axisTicks: {
                show: true,
              },
              labels: {
                show: true,
                rotate: -45,
                rotateAlways: true,
                formatter: (timestamp) => this.$moment.tz(timestamp, this.equipment.location.timezone || 'UTC').format('HH:mm [on] DD MMM'),
              },
              tooltip: {
                enabled: false,
                style: {
                  colors: this.$metronic.config.layout.get('colors.gray.gray-500'),
                  fontSize: '12px',
                  fontFamily: this.$metronic.config.layout.get('font-family'),
                },
              },
            },
            yaxis: [{
              seriesName: 'Temperature',
              min: 100,
              max: 600,
              forceNiceScale: true,
              labels: {
                show: true,
                formatter: (value) => `${value / 10} °C`,
              },
            }],
            markers: {
              colors: [
                this.$metronic.config.layout.get('colors.theme.light.primary'),
                this.$metronic.config.layout.get('colors.theme.light.info'),
                this.$metronic.config.layout.get('colors.theme.light.info'),
              ],
              strokeColor: [
                this.$metronic.config.layout.get('colors.theme.base.primary'),
                this.$metronic.config.layout.get('colors.theme.base.info'),
                this.$metronic.config.layout.get('colors.theme.base.info'),
              ],
              strokeWidth: 3,
            },
          },
          series: [],
        },
      },

      format,
    };
  },
  components: {
    apexchart: ApexCharts,
  },
  computed: {
    lampTemperatureSensorRow () {
      const health = SunbedHealth.find((row) => row.model.includes(this.equipment.model));
      const sensor = (health && health.temperatures && health.temperatures.find((row) => row.session_temperature === true));
      return sensor || undefined;
    },
    lampTemperatureSensor () {
      return (this.lampTemperatureSensorRow && this.lampTemperatureSensorRow.sensor) || undefined;
    },
    lampTemperatureSeries () {
      const series = (this.temperatures.chart.series || []).find((row) => row.sensor === this.lampTemperatureSensor);
      return (series && series.data) || [];
    },
    lampTemperatureChartOptions () {
      let options = cloneDeep(this.temperatures.chart.options);

      // Modify options, if necessary
      if (this.lampTemperatureSensorRow && typeof this.lampTemperatureSensorRow.modifyChartOptions === 'function') {
        options = this.lampTemperatureSensorRow.modifyChartOptions(options);
      }

      return options;
    },

    averageLampTemperature () {
      return (this.lampTemperatureSeries.reduce((value, row) => value + row[1], 0)) / this.lampTemperatureSeries.length;
    },
    maximumLampTemperature () {
      return (this.lampTemperatureSeries.reduce((value, row) => (value > row[1] ? value : row[1]), this.averageLampTemperature));
    },
    minimumLampTemperature () {
      return (this.lampTemperatureSeries.reduce((value, row) => (value < row[1] ? value : row[1]), this.averageLampTemperature));
    },
  },

  mounted () {
    // Listeners
    this.$eventhub.on(`modals:${this.event}:open`, this.open);
    this.$eventhub.on(`modals:${this.event}:close`, this.close);
  },
  beforeDestroy () {
    // Listeners
    this.$eventhub.off(`modals:${this.event}:open`, this.open);
    this.$eventhub.off(`modals:${this.event}:close`, this.close);
  },

  methods: {
    open (options = {}) {
      this.$set(this, 'log', null);
      this.$set(this, 'options', options || {});

      if (!this.options.log || this.options.log instanceof LuxuraLogSession === false) {
        throw new Error('Session log missing');
      }

      this.$set(this, 'log', this.options.log);

      // Set date range based by log date
      this.$set(this.temperatures, 'dateRange', [
        // Start date is the log date minus the session time
        this.$moment.utc(this.log.log_date).subtract((this.log.session.session_time + this.log.session.pause_time), 'seconds'),

        // End date is just the log date itself
        this.$moment.utc(this.log.log_date),
      ]);

      // Get temperature data
      this.getTemperatureChartData();

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    onShown () {
      // Nothing do to here
    },

    async getTemperatureChartData () {
      this.$set(this.temperatures.chart, 'series', []);
      this.$set(this.temperatures, 'isLoading', true);

      try {
        const response = await this.$ws.get(`${Equipment.uri}/statistics/temperatures/${this.lampTemperatureSensor}/1m/${this.temperatures.dateRange[0].valueOf()}/${this.temperatures.dateRange[1].valueOf()}`, {
          query: {
            equipment: this.equipment._meta.guid,
            offset: this.equipment.location.timezone || 'UTC',
          },
        });

        this.$set(this.temperatures.chart, 'series', this.configureTemperatureData(response.results));
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$set(this.temperatures, 'isLoading', false);
      }
    },

    configureTemperatureData (temperatures = null) {
      if (!temperatures || Array.isArray(temperatures) === false) {
        return [];
      }

      // Initialise series
      const series = [];

      // Set series first with temperature series
      series.push({
        sensor: this.lampTemperatureSensor,
        name: 'Temperature',
        data: temperatures.map((row) => [this.$moment.tz(row.date, this.equipment.location.timezone || 'UTC').valueOf(), row.average]),
      });

      // Create desired course
      const optimalTemperature = [250, 300, 350, 400];
      const desiredCourse = {
        max: [],
        min: [],
      };
      const sessionMinutes = this.$moment.duration(this.temperatures.dateRange[1].diff(this.temperatures.dateRange[0])).asMinutes();

      // eslint-disable-next-line no-plusplus
      for (let minute = 0; minute < sessionMinutes; minute++) {
        const date = this.temperatures.dateRange[0]
          .clone()
          .tz(this.equipment.location.timezone || 'UTC')
          .add(minute, 'minutes')
          .set('seconds', 0)
          .valueOf();

        const courseValue = (
          optimalTemperature[(minute)]
            ? optimalTemperature[(minute)]
            : optimalTemperature[optimalTemperature.length - 1]
        );

        desiredCourse.max.push([date, (courseValue + 80)]);
        desiredCourse.min.push([date, (courseValue - 80)]);
      }

      series.push({
        name: 'Desired course upper',
        data: desiredCourse.max,
      }, {
        name: 'Desired course lower',
        data: desiredCourse.min,
      });

      return series;
    },
  },
};
</script>
